/* eslint-disable prettier/prettier */

export const getProfileCalibrationsByConfig = /* GraphQL */ `
  query ProfileCalibrationsByConfig(
    $calibrationConfigID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileCalibrationStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalibrationsByConfig(
      calibrationConfigID: $calibrationConfigID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        organization {
          name
        }
        calibrationstatus
        profile {
          id
          alias
          name
          level
          manager
          email
          status
          preferredTimezone
          activeOrg
          createdAt
          updatedAt
          title
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getProfilePreferences = /* GraphQL */`
  query GetProfilePreferences($id: ID!) {
    getProfile(id: $id) {
      id
      alias
      name
      email
      level
      br
      brit
      status
      loopInterest
      phoneScreenInterest
      brInterest
      diversityInterviewer
      notifyWeeklyDigest
      notifyLoopOpportunity
      notifyPhoneScreenOpportunity
      preferredTimezone
      preferencesLastUpdateDate
      preferencesLastUpdateBy
      activeOrg
      createdAt
      updatedAt
    }
  }
`;

export const getProfileAvailabilityAndCalibration = /* GraphQL */ `
  query getProfileAvailabilityAndCalibration(
    $id: ID!
    $orgID: ID!
    $calibrationStatusFilter: ModelProfileCalibrationStatusFilterInput
  ) {
    getProfile(id: $id) {
      id
      alias
      name
      email
      level
      manager
      br
      brit
      status
      preferredTimezone
      availabilities(filter: { expired: { eq: false } }, limit: 1000) {
        items {
          id
          profileID
          orgID
          state
          sendCalendarInvite
          eventType
          availabilityDate
          availabilityOpen
          availabilityClose
          availabilitySeriesID
          recurringEndDate
          daysOfWeek
          recursion
          rrule
          createdAt
          updatedAt
        }
        nextToken
      }
      calibrations(orgID: { eq: $orgID }, filter: $calibrationStatusFilter, limit: 100) {
        items {
          id
          orgID
          cohortEntryDate
          cohortExitDate
          shadowCount
          reverseShadowCount
          independentCount
          reviewCount
          calibrationstatus
          calibrationConfigID
          calibrationConfig {
            id
            orgID
            configType
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const getProfileOrgV2 = /* GraphQL */ `
  query GetProfileOrganizationV2($profileID: ID!, $orgID: ID!) {
    getProfileOrganizationV2(profileID: $profileID, orgID: $orgID) {
      profileID
      orgID
      role
    }
  }
`;

export const getProfilesByOrgAndRole = /* GraphQL */ `
  query GetProfilesByOrgAndRole(
    $orgID: ID!
    $profileID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileOrganizationV2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfilesByOrgV2(
      orgID: $orgID
      profileID: $profileID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        profileID
        orgID
        organization {
          name
        }
        role
        createdAt
        updatedAt
        profile {
          alias
          createdAt
          email
          id
          l2manager
          level
          manager
          name
          preferredTimezone
          status
          title
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const listProfilesByManager = /* GraphQL */ `
  query listProfilesByManager (
    $managerAlias: String!
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfilesByManager(
      manager: $managerAlias,
      status: { eq: "ACTIVE" },
      filter: $filter,
      limit: $limit,
      nextToken: $nextToken
    ) {
      items {
        id
        alias
        brStatus
        calibrations(
          filter: { calibrationConfigID: { attributeExists: true } }
          limit: $limit
        ) {
          items {
            calibrationstatus
            calibrationConfigID
            shadowCount
            reverseShadowCount
            independentCount
            reviewCount
          }
        }
        email
        level
        manager
        l2manager
        name
        status
        title
        updatedAt
      }
      nextToken
    }
  }
`;

export const getProfilesWithCalibration = /* GraphQL */ `
  query GetProfilesByOrg(
    $orgID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileOrganizationV2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfilesByOrgV2(
      orgID: $orgID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        profile {
          id
          alias
          brStatus
          name
          level
          manager
          l2manager
          title
          email
          status
          preferredTimezone
          calibrations(
            orgID: { eq: $orgID }
            filter: { calibrationConfigID: { attributeExists: true } },
            limit: $limit
          ) {
            items {
              calibrationstatus
              calibrationConfigID
              shadowCount
              reverseShadowCount
              independentCount
              reviewCount
            }
          }
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const getProfileCalibrations = /* GraphQL */ `
  query GetProfileCalibrations(
    $id: ID!
    $limit: Int
    $nextToken: String
    $orgID: ModelIDKeyConditionInput
    $calibrationsFilter: ModelProfileCalibrationStatusFilterInput
  ) {
    getProfile(id: $id) {
      id
      alias
      name
      level
      br
      brit
      calibrations(orgID: $orgID, filter: $calibrationsFilter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          orgID
          cohortEntryDate
          cohortExitDate
          shadowCount
          reverseShadowCount
          independentCount
          reviewCount
          calibratedDate
          lastModifiedBy
          changeJustification
          calibrationstatus
          calibrationConfigID
          calibrationConfig {
            id
            orgID
            configType
            isActive
            name
            description
            maxShadowPoolSize
            minIndependent
            minShadows
            minReverseShadows
            minShepherdReviews
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const getBaseProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      alias
      firstName
      lastName
      name
      title
      email
      level
      br
      brit
      activeOrg
      createdAt
      updatedAt
    }
  }
`;

export const getProfileDetail = /* GraphQL */ `
  query GetProfileDetail($id: ID!) {
    getProfile(id: $id) {
      id
      alias
      firstName
      lastName
      name
      email
      level
      hireDate
      city
      site
      manager
      br
      brit
      status
      preferredTimezone
      activeOrg
      orgs(limit: 1000) {
        items {
          orgID
        }
        nextToken
      }
      calibrationShepherds {
        items {
          id
          calibrationShepherdProfileID
          userProfileID
          calibrationShepherdProfile {
            id
            alias
            name
            email
            level
            title
          }
          lastModifiedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      title
      createdAt
      updatedAt
    }
  }
`;

export const getPeopleprofile = /* GraphQL */ `
  query GetPeopleProfile($alias: String!) {
    getPeopleprofile(alias: $alias) {
      username
      employeeId
      isBarRaiser
      brit
    }
  }
`;

export const getCalibrationCohort = /* GraphQL */ `
  query getCalibrationCohort(
    $id: ID!
    $limit: Int
  ) {
    listCalibrationsByConfig(
      calibrationConfigID: $id,
      filter: { calibrationstatus: { eq: IN_CALIBRATION } },
      limit: $limit
    ) {
      items {
        profileID
      }
      nextToken
    }
  }
`;

export const getProfileLanguagesByProfileId = /* GraphQL */ `
  query GetProfileLanguagesByProfileId($id: ID!) {
    getProfileLanguageByProfileId(profileId: $id) {
      items {
        id
        isoCode
        proficiencySpeaking
        proficiencyReading
        proficiencyWriting
      }
    }
  }
`;

export const getProfileByAliasClassic = /* GraphQL */`
  query GetProfileByAliasClassic(
    $alias: String!
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findProfileByAlias(alias: $alias, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        activeOrg
        alias
        name
        title
        email
        employeeId
        level
        br
        brit
        manager
        preferredTimezone
        calibrations {
          items {
            calibrationstatus
            id
            orgID
            calibrationConfigID
            shadowCount
            independentCount
            reverseShadowCount
            reviewCount
          }
        }
        orgs {
          items {
            organization {
              name
              id
              roleLDAPGroups {
                items {
                  group
                  role
                }
              }
              status
            }
          }
        }
      }
    }
  }
`;

export const getProfileByAliasPlatform = /* GraphQL */`
  query GetProfileByAliasPlatform(
    $alias: String!
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findProfileByAlias(alias: $alias, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        activeOrg
        alias
        name
        title
        email
        employeeId
        level
        br
        brit
        manager
        preferredTimezone
        calibrations {
          items {
            calibrationstatus
            id
            orgID
            calibrationConfigID
            shadowCount
            independentCount
            reverseShadowCount
            reviewCount
          }
        }
        orgs {
          items {
            organization {
              name
              id
              roleGroups {
                items {
                  app
                  group
                  role
                }
              }
              status
            }
          }
        }
      }
    }
  }
`;

export const getQuestionBankTeam = /* GraphQL */ `
  query GetQuestionBankTeam($id: ID!) {
    getQuestionBankTeam(id: $id) {
      id
      name
      group
      createdAt
      updatedAt
    }
  }
`;

export const listQuestionBankTeams = /* GraphQL */ `
  query ListQuestionBankTeams($limit: Int, $nextToken: String) {
    listQuestionBankTeams(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
        group
        createdAt
        updatedAt
      }
    }
  }
`;

export const listQuestionBankTeamCategories = /* GraphQL */ `
  query ListQuestionBankTeamCategories($teamID: ID!) {
    listQuestionBankCategoriesByTeam(teamID: $teamID) {
      items {
        id
        group
        name
        description
        teamID
        createdAt
        updatedAt
        team {
          id
          name
          group
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const listQuestionBankTeamJobRoles = /* GraphQL */ `
  query ListQuestionBankTeamJobRoles($teamID: ID!) {
    listQuestionBankJobRolesByTeam(teamID: $teamID) {
      items {
        id
        group
        name
        description
        teamID
        createdAt
        updatedAt
        team {
          id
          name
          group
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const listQuestionBankTeamInterviewerRoles = /* GraphQL */ `
  query ListQuestionBankTeamInterviewerRoles($teamID: ID!) {
    listQuestionBankInterviewerRolesByTeam(teamID: $teamID) {
      items {
        id
        group
        name
        description
        teamID
        createdAt
        updatedAt
        team {
          id
          name
          group
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const listQuestionBankTeamQuestions = /* GraphQL */ `
  query ListQuestionBankTeamQuestions($teamID: ID!) {
    listQuestionBankQuestionsByTeam(teamID: $teamID) {
      items {
        id
        category {
          id
          group
          name
          description
          teamID
          createdAt
          updatedAt
          team {
            id
            name
            group
            createdAt
            updatedAt
          }
        }
        categoryID
        status
        group
        name
        description
        teamID
        createdAt
        updatedAt
        shareability
        team {
          id
          name
          group
          createdAt
          updatedAt
        }
      }
    }
  }
`;
